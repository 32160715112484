<script lang="ts" setup>
import type { ILeaseSummaryField, ILeaseSummaryFieldValue } from '@register'

interface Props {
  field: ILeaseSummaryField
  value: ILeaseSummaryFieldValue
}
const props = defineProps<Props>()

const currentValue = computed(() => {
  return props.value ?? props.field.value
})
</script>

<template>
  <span>
    <span v-if="!!currentValue.value?.googleAddress">
      {{ currentValue.value.googleAddress }}
    </span>
    <span
      v-else-if="!!currentValue.value.address"
      class="relative flex flex-col"
    >
      {{ currentValue.value.address }}
    </span>
    <span v-else> N/A </span>
  </span>
</template>
